import styled from 'styled-components';
import THEMES from '../../../styles/themes/app';

export const getTextStyles = () => `
  word-wrap: break-word;
`;

export const getTextSize = () => `
  font-size: 14px;
`;

export const getEntityCardStyle = () => `
  background-color: transparent;
  border:0;
`;

export const FlexData = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  min-width: 250px;
`;

export const FeedbackDetailsContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_QUATERNARY};
  border-radius: 8px;
  flex: 4;
  height: calc(100vh - 344px);
  margin: 40px 0 16px 0;
  width: 438px;
`;

export const TextContainer = styled.div`
  height: calc(100% - 90px);
  margin: 0px 16px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 12px;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ReplyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
