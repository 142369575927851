import {
  isNil,
  isEmpty,
  compose,
  pipe,
  equals,
  either,
  trim,
  type,
  cond,
  T,
} from 'ramda';
import moment from 'moment';
import libSlugify from 'slugify';
import i18n from 'i18n-js';
import CUSTOMER_CONTACT_FRAGMENT from '../graphql/fragments/CustomerContactFragment.graphql';
import CUSTOMER_ACCOUNT_INFO_FRAGMENT from '../graphql/fragments/CustomerAccountInfoFragment.graphql';
import USER_FRAGMENT from '../graphql/fragments/UserFragment.graphql';
import CUSTOMER_ACCOUNT_QUERY from '../graphql/query/CustomerAccountQuery.graphql';
import CUSTOMER_CONTACT_QUERY from '../graphql/query/CustomerContactQuery.graphql';
import GROUP_FRAGMENT from '../graphql/fragments/GroupFragment.graphql';
import GROUP_QUERY from '../graphql/query/GroupQuery.graphql';
import USER_QUERY from '../graphql/query/UserQuery.graphql';
import { emailRegex } from './regex';

/**
 * 1) GENERAL
 *  -- getSubdomain
 *  -- slugify
 *  -- getItemId
 *  -- formatPhoneNumber
 *  -- isHolidaySeason
 *  -- getAvailableThemes
 *  -- containsNoValue
 *  -- shouldPadLeftWithZeros
 *  -- stringContains
 *  -- arrayToObject
 *  -- debounce
 *  -- COMMON_NORMALIZERS
 *  -- verifyAndTruncateLongName
 *  -- genRandId
 *  -- CHANNEL_TYPE
 *  -- Times in MS
 *    -- // Time in ms
 *    -- const FIVE_MIN = 300000;
 *    -- const ONE_HOUR = 3600000;
 *    -- const FOUR_HOURS = 14400000;
 *    -- const EIGHT_HOURS = 28800000;
 *
 *  2) CONTACT & USER DATA MANIPULATION
 *  -- phoneNumber

export const CHANNEL_TYPE = {
  announcements: 'announcements',
  conversations: 'conversations',
  email: 'email',
  fax: 'fax',
  webchat: 'webchat',
};

 *  -- accountName
 *  -- accountNumber
 *  -- contactName
 *  -- getCustomerName
 *  -- resolveAvatarUrl
 *  -- getEntityData
 *  -- compareUsersByFullName
 *
 *  3) DATES & TIME
 *  -- formatDatetimeToDateComplete
 *  -- formatDateToDateComplete
 *  -- formatTimeToHours
 *  -- getCurrentOrTomorrowDate
 *  -- hourOptions
 *  -- formatDate
 *  -- calendar_util
 *
 *  4) Attachments & Files
 *  -- fileExtension
 *  -- isAttachmentFileTypeSupported
 *  -- canPreview
 *  -- isAnimatedGif
 *  -- fileNameWithoutExtension
 *  -- ALLOWED_FILENAME_REGEX
 *  -- NOT_ALLOWED_FILENAME_REGEX
 *  -- validImage
 *  -- canPreviewBasedOnFileExtension
 *  -- fileReader
 *
 *  5) TEXT INPUT
 *  -- byteCount
 *  -- excerptText
 *
 *  6) THREADS
 *  -- isThreadUnread
 *  -- findInternalThreadExternalUser
 *  -- internalThreadExternalUserName
 *  -- isInternalThreadAndOwnedByUser
 * 
 *  7) WIDGETS
 *  -- WIDGET_TYPE
 *  
 *  8) DASHBOARDS
 *  -- sortedGroupThreadsByTime
 *  -- DEFAULT_DASHBOARD_WIDGET_ROW_COUNT
 *  -- DEFAULT_DASHBOARD_FLYOUT_ROW_COUNT
 */

/**
---------------------------------------------------
 */

/**
 * GENERAL
 */
export const getSubdomain = (url) => {
  let domain = url;
  if (url.includes('://')) {
    domain = url.split('://')[1];
  }
  const subdomain = domain.split('.')[0];
  return subdomain;
};

export const slugify = (val) =>
  typeof val === 'string' ? libSlugify(val, { lower: true }) : '';

export const getItemId = (item) => item.id;

// Convert phone numbers into formated versions (555) 555-5555
export const formatPhoneNumber = (s) => {
  const s2 = ('' + s).replace(/\D/g, '');
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? s : '(' + m[1] + ') ' + m[2] + '-' + m[3];
};

export const checkBlockedChannelType = (channel) => {
  if (emailRegex.test(channel)) {
    return channel;
  }
  return formatPhoneNumber(channel);
};

export const isHolidaySeason = () => {
  const date = new Date();
  const month = date.getMonth();

  const isInDevEnv =
    process.env.SENTRY_ENVIRONMENT === 'development' ||
    process.env.SENTRY_ENVIRONMENT === 'qa';

  return month === 11 || month === 10 || isInDevEnv;
};

/**
 * Defines the available theme modes.
 *
 * @constant {Object} THEME_MODES
 * @property {string} classic - The classic theme mode.
 * @property {string} light - The light theme mode.
 * @property {string} dark - The dark theme mode.
 * @property {string} holiday - The holiday theme mode.
 */
export const THEME_MODES = {
  classic: 'classic',
  light: 'light',
  dark: 'dark',
  holiday: 'holiday',
};

export const getAvailableThemes = () => {
  const themes = ['light', 'dark', 'classic'];
  if (isHolidaySeason()) themes.push('holiday');
  return themes;
};

export const containsNoValue = cond([
  [pipe(type, equals('Array')), either(isNil, isEmpty)],
  [pipe(type, equals('Object')), either(isNil, isEmpty)],
  [pipe(type, equals('String')), either(isNil, compose(isEmpty, trim))],
  [T, isNil],
]);

export const shouldPadLeftWithZeros = (value) =>
  value.toString().length === 1 ? value.toString().padStart(2, '0') : value;

export const stringContains = (str, val) => {
  if (typeof str !== 'string' || typeof val !== 'string') return false;
  return str.toLowerCase().includes(val.toLowerCase());
};

export const arrayToObject = (arr, propNamePosition) =>
  arr.reduce((obj, val) => {
    const newObj = obj;

    if (propNamePosition) newObj[val[propNamePosition]] = val;
    else newObj[val] = val;

    return newObj;
  }, {});

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * `wait` milliseconds
 * @param {Function} func function to call after period of time
 * @param {Number} wait ms to wait
 * @param {Boolean} immediate trigger the function, instead of waiting
 */
export function debounce(func, wait = 0, immediate = false) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    }, wait);
    if (immediate && !timeout) func.apply(this, [...args]);
  };
}

export const COMMON_NORMALIZERS = {
  normalizePhone: (value) => {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length <= 3) {
      return onlyNums;
    }
    if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      onlyNums.length,
    )}`;
  },
};

export const verifyAndTruncateLongName = (name) => {
  let formattedName;
  const MAX_CHARACTERS_IN_NAME = 30;
  if (name.length > MAX_CHARACTERS_IN_NAME) {
    formattedName = `${name.slice(0, 25)}...`;
  } else {
    formattedName = name;
  }
  return formattedName;
};

export const genRandId = (digits = 4) => {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
  const uuid = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < digits; i++) {
    uuid.push(str[Math.floor(Math.random() * str.length)]);
  }
  return uuid.join('');
};

export const CHANNEL_TYPE = {
  announcements: 'announcements',
  conversations: 'conversations',
  email: 'email',
  fax: 'fax',
  webchat: 'webchat',
};

// Time in ms
const FIVE_MIN = 300000;
const ONE_HOUR = 3600000;
const FOUR_HOURS = 14400000;
const EIGHT_HOURS = 28800000;

/**
---------------------------------------------------
 */

/**
 * CONTACT DATA MANIPULATION
 */

export const phoneNumber = (entity) => {
  if (entity && entity.phoneNumber) {
    return entity.phoneNumber;
  }
  return '';
};

export const faxNumber = (entity) => {
  if (entity && entity.faxNumber) {
    return entity.faxNumber;
  }
  return '';
};

export const accountName = (entity) => {
  if (entity) {
    if (entity.__typename === 'CustomerAccount') {
      return entity.name;
    }

    if (entity.__typename === 'CustomerContact' && entity.account !== null) {
      return entity.account.name;
    }
    return '';
  }

  return '';
};

export const accountNumber = (entity) => {
  if (entity) {
    if (entity.__typename === 'CustomerAccount') {
      return entity.accountNumber;
    }

    if (entity.__typename === 'CustomerContact' && entity.account !== null) {
      return entity.account.accountNumber;
    }
    return '';
  }

  return '';
};

export const contactName = (entity) => {
  if (entity) {
    // return present name values
    if (entity.firstName && entity.lastName) {
      return `${entity.firstName} ${entity.lastName}`;
    }

    // Return translated rule: if contact is an automated rule
    if (
      !entity.lastName &&
      entity.firstName &&
      entity.firstName.startsWith('rule: ')
    ) {
      return i18n.t('settings-manageRules-rule', {
        defaultValue: `rule: ${entity.firstName}`,
        ruleName: entity.firstName.replace('rule: ', ''),
      });
    }

    if (entity.firstName) {
      return entity.firstName;
    }

    if (entity.lastName) {
      return entity.lastName;
    }

    // if no name try to return phone number
    if (entity.phoneNumber !== null && entity.phoneNumber !== '') {
      return formatPhoneNumber(entity.phoneNumber);
    }

    if (entity.emailAddress) {
      return entity.emailAddress;
    }

    if (entity.faxNumber !== null && entity.faxNumber !== '') {
      return formatPhoneNumber(entity.faxNumber);
    }

    return '';
  }

  return '';
};

export const getCustomerName = (entity) => {
  if (entity.__typename === 'CustomerAccount') {
    return accountName(entity);
  }
  if (entity.__typename === 'CustomerContact') {
    return contactName(entity);
  }

  return null;
};

export const resolveAvatarUrl = (entity) => {
  if (entity?.__typename === 'User') {
    return entity?.avatarUrl;
  }
  return null;
};

export const isCustomerContactUnknown = (contact) => {
  return (
    contact?.account === null &&
    contact?.firstName === null &&
    contact?.lastName === null
  );
};

export const getEntityData = ({ client, customerId }) => {
  try {
    const customer = client.readFragment({
      id: 'CustomerContact_' + customerId,
      fragment: CUSTOMER_CONTACT_FRAGMENT,
      fragmentName: 'CustomerContactFragment',
    });
    if (customer !== null && customer.id !== undefined) {
      return customer;
    }

    return client.readFragment({
      id: 'CustomerAccount_' + customerId,
      fragment: CUSTOMER_ACCOUNT_INFO_FRAGMENT,
      fragmentName: 'CustomerAccountInfoFragment',
    });
  } catch (e) {
    console.log('Customer data Not In Cache: ', e.message);
    return null;
  }
};

export const compareUsersByFullName = (a, b) => {
  let aString = `${a.firstName} ${a.lastName}`;
  let bString = `${b.firstName} ${b.lastName}`;

  if (aString.trim() === '') {
    aString = 'zzzzzzzzzz';
  }

  if (bString.trim() === '') {
    bString = 'zzzzzzzzzz';
  }

  return aString.localeCompare(bString);
};

/**
---------------------------------------------------
 */

export const formatAddress = (address) => {
  if (address) {
    const {
      address_line_1,
      address_line_2,
      locality,
      administrative_district_level_1,
      postalCode,
      country,
    } = address;
    const formattedLines = {
      line1: `${address_line_1 !== null ? address_line_1 : ''} ${
        address_line_2 !== null ? address_line_2 : ''
      }`,
      line2: `${locality !== null ? `${locality},` : ''} ${
        administrative_district_level_1 !== null
          ? administrative_district_level_1
          : ''
      } ${postalCode !== null ? postalCode : ''}`,
      line3: `${country !== null ? country : ''}`,
    };

    return formattedLines;
  }
  return '';
};

export const provincesOptions = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'YT', label: 'Yukon' },
];

export const countryOptions = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
];

export const stateOptions = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'GU', label: 'Guam' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'VI', label: 'Virgin Islands' },
];

/**
 * DATES & TIME
 */

/** Format from datetime to DD-MM-YYYY hh:mm */
export const formatDatetimeToDateComplete = (date) =>
  moment(date).format('M/D/YYYY h:mma');

export const formatDateToDateComplete = (date) =>
  moment(date).format('M/D/YYYY');

/** Format from time to hh:mm */
export const formatTimeToHours = (time) => moment(time).format('h:mma');

export const getCurrentOrTomorrowDate = (day) => {
  let today = new Date();
  let dd;
  if (day === 'today') {
    dd = today.getDate();
  } else {
    dd = today.getDate() + 1;
  }

  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  today = `${yyyy}-${mm}-${dd}`;
  return today;
};

export const hoursOptions = [
  {
    label: '12:00am',
    value: '00:00:00',
  },
  {
    label: '12:30am',
    value: '00:30:00',
  },
  {
    label: '1:00am',
    value: '01:00:00',
  },
  {
    label: '1:30am',
    value: '01:30:00',
  },
  {
    label: '2:00am',
    value: '02:00:00',
  },
  {
    label: '2:30am',
    value: '02:30:00',
  },
  {
    label: '3:00am',
    value: '03:00:00',
  },
  {
    label: '3:30am',
    value: '03:30:00',
  },
  {
    label: '4:00am',
    value: '04:00:00',
  },
  {
    label: '4:30am',
    value: '04:30:00',
  },
  {
    label: '5:00am',
    value: '05:00:00',
  },
  {
    label: '5:30am',
    value: '05:30:00',
  },
  {
    label: '6:00am',
    value: '06:00:00',
  },
  {
    label: '6:30am',
    value: '06:30:00',
  },
  {
    label: '7:00am',
    value: '07:00:00',
  },
  {
    label: '7:30am',
    value: '07:30:00',
  },
  {
    label: '8:00am',
    value: '08:00:00',
  },
  {
    label: '8:30am',
    value: '08:30:00',
  },
  {
    label: '9:00am',
    value: '09:00:00',
  },
  {
    label: '9:30am',
    value: '09:30:00',
  },
  {
    label: '10:00am',
    value: '10:00:00',
  },
  {
    label: '10:30am',
    value: '10:30:00',
  },
  {
    label: '11:00am',
    value: '11:00:00',
  },
  {
    label: '11:30am',
    value: '11:30:00',
  },
  {
    label: '12:00pm',
    value: '12:00:00',
  },
  {
    label: '12:30pm',
    value: '12:30:00',
  },
  {
    label: '1:00pm',
    value: '13:00:00',
  },
  {
    label: '1:30pm',
    value: '13:30:00',
  },
  {
    label: '2:00pm',
    value: '14:00:00',
  },
  {
    label: '2:30pm',
    value: '14:30:00',
  },
  {
    label: '3:00pm',
    value: '15:00:00',
  },
  {
    label: '3:30pm',
    value: '15:30:00',
  },
  {
    label: '4:00pm',
    value: '16:00:00',
  },
  {
    label: '4:30pm',
    value: '16:30:00',
  },
  {
    label: '5:00pm',
    value: '17:00:00',
  },
  {
    label: '5:30pm',
    value: '17:30:00',
  },
  {
    label: '6:00pm',
    value: '18:00:00',
  },
  {
    label: '6:30pm',
    value: '18:30:00',
  },
  {
    label: '7:00pm',
    value: '19:00:00',
  },
  {
    label: '7:30pm',
    value: '19:30:00',
  },
  {
    label: '8:00pm',
    value: '20:00:00',
  },
  {
    label: '8:30pm',
    value: '20:30:00',
  },
  {
    label: '9:00pm',
    value: '21:00:00',
  },
  {
    label: '9:30pm',
    value: '21:30:00',
  },
  {
    label: '10:00pm',
    value: '22:00:00',
  },
  {
    label: '10:30pm',
    value: '22:30:00',
  },
  {
    label: '11:00pm',
    value: '23:00:00',
  },
  {
    label: '11:30pm',
    value: '23:30:00',
  },
];

export const formatDate = (date) => {
  const dateToArray = date.split('-');
  return `${dateToArray[1]}-${dateToArray[2]}-${dateToArray[0]}`;
};

export const calendar_util = {
  dateYYYYMMDDtoDateObj: (value) => {
    const [year, month, day] = value.split('-');
    return new Date(year, month - 1, day);
  },
  dateObjToYYYYMMDD: (value) => value?.toISOString()?.split('T')[0],
};

/**
---------------------------------------------------
 */

/**
 * ATTACHMENTS & FILES
 */

/* Extracts file extension */
export const fileExtension = (filename) => {
  if (!filename) return '';
  const index = filename.lastIndexOf('.');
  return filename.substring(index + 1).toLowerCase() || '?';
};

export const isAttachmentFileTypeSupported = (filename = '') => {
  const [name, ...extensions] = filename.toLowerCase().split('.'); // eslint-disable-line no-unused-vars
  let unsupported;
  // temporary fix to avoid crash, we should allow gif in mobile
  if (process.env.PLATFORM === 'mobile') {
    unsupported = ['exe', 'app', 'svg', 'gif', 'gdoc', 'gsheet', 'gslides'];
  } else {
    unsupported = ['exe', 'app', 'svg', 'dmg'];
  }

  const fileUnsupportedExtensions = extensions.filter((ext) =>
    unsupported.includes(ext),
  );

  return !fileUnsupportedExtensions.length;
};

const canPreview = (extension) => {
  if (
    extension === 'jpg' ||
    extension === 'jpeg' ||
    extension === 'png' ||
    extension === 'gif'
  ) {
    return true;
  }
  return false;
};

/* eslint-disable */
const dataAnimationAnalyze = (arrayBuffer) => {
  const bytes = new Uint8Array(arrayBuffer);
  const buffer = bytes.buffer;

  const HEADER_LEN = 6; // offset bytes for the header section
  const LOGICAL_SCREEN_DESC_LEN = 7; // offset bytes for logical screen description section

  // Start from last 4 bytes of the Logical Screen Descriptor
  const dv = new DataView(buffer, HEADER_LEN + LOGICAL_SCREEN_DESC_LEN - 3);
  let offset = 0;
  const globalColorTable = dv.getUint8(0); // aka packet byte
  let globalColorTableSize = 0;

  // check first bit, if 0, then we don't have a Global Color Table
  if (globalColorTable & 0x80) {
    // grab the last 3 bits, to calculate the global color table size -> RGB * 2^(N+1)
    // N is the value in the last 3 bits.
    globalColorTableSize = 3 * 2 ** ((globalColorTable & 0x7) + 1);
  }

  // move on to the Graphics Control Extension
  offset = 3 + globalColorTableSize;

  const extensionIntroducer = dv.getUint8(offset);
  const graphicsConrolLabel = dv.getUint8(offset + 1);
  let delayTime = 0;

  // Graphics Control Extension section is where GIF animation data is stored
  // First 2 bytes must be 0x21 and 0xF9
  if (extensionIntroducer & 0x21 && graphicsConrolLabel & 0xf9) {
    // skip to the 2 bytes with the delay time
    delayTime = dv.getUint16(offset + 4);
  }

  return delayTime > 0;
};

export const isAnimatedGif = (src, cb) => {
  const request = new XMLHttpRequest();
  request.open('GET', src, true);
  request.responseType = 'arraybuffer';
  request.addEventListener('load', () => {
    const arrayBuffer = request.response;
    cb(dataAnimationAnalyze(arrayBuffer));
  });
  request.send();
};
/* eslint-enable */

export const fileNameWithoutExtension = (filename) => {
  if (!filename) return '';
  return filename.substr(0, filename.lastIndexOf('.'));
};

export const ALLOWED_FILENAME_REGEX = /^[0-9a-zA-Z._-\s]+$/;
export const NOT_ALLOWED_FILENAME_REGEX = /[&\/\\#,+()!ªº"·&¿?$~%'":*?<>{}]/g; // eslint-disable-line no-useless-escape

export const validImage = (extension) =>
  extension === '.jpg' || extension === '.jpeg' || extension === '.png';

export const canPreviewBasedOnFileExtension = (filename) =>
  canPreview(fileExtension(filename));

/**
 * Returns a valid file, from a given one
 * @param file
 * @param callback
 */
export const fileReader = (file, callback) => {
  const reader = new window.FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event) => {
    callback(file, event.target.result);
  };
};

/**
---------------------------------------------------
 */

/**
 * TEXT INPUT
 */
export const byteCount = (str) => encodeURI(str).split(/%..|./).length - 1;

export const excerptText = (fullText, targetText, fragmentLength) => {
  if (!targetText) return fullText;
  const lowerFullText = fullText.toLowerCase();
  const lowerTargetText = targetText.toLowerCase();
  const leadLength = Math.floor(fragmentLength / 2);
  const lagLength = leadLength + targetText.length;
  const groups = [];

  let index = lowerFullText.indexOf(lowerTargetText);

  while (index >= 0) {
    groups.push(index);

    index = lowerFullText.indexOf(lowerTargetText, index + targetText.length);
  }

  if (groups.length === 0) {
    if (fullText.length > fragmentLength) {
      return fullText.substring(0, fragmentLength);
    }

    return fullText;
  }

  const fragments = [];
  let start = 0;
  let end = fragmentLength - 1;
  let continuation = false;

  for (let i = 0; i < groups.length; i += 1) {
    const group = groups[i];
    const nextGroup = groups[i + 1];

    if (!continuation) {
      if (group > leadLength) {
        start = group - leadLength;
      } else {
        start = 0;
      }
    }

    if (!nextGroup || nextGroup > group + lagLength) {
      if (group + lagLength - 1 < fullText.length) {
        end = group + lagLength;
      } else {
        end = fullText.length;
      }

      continuation = false;

      let fragment = fullText.substring(start, end);

      if (i === 0 && start !== 0) {
        fragment = '...' + fragment;
      }

      if (!nextGroup && end < fullText.length - 1) {
        fragment += '...';
      }

      fragments.push(fragment);
    } else {
      continuation = true;
    }
  }

  return fragments.join('...');
};

/**
---------------------------------------------------
 */

/**
 * THREADS
 */

export const isThreadUnread = (thread, currentContactId) => {
  const participant =
    thread.participants.find((p) => p.contactId === currentContactId) || null;
  const lastReadAt = participant !== null ? participant.readAt : null;
  const unread =
    lastReadAt === null || lastReadAt < thread?.latestMessage?.createdAt;

  return unread;
};

export const findInternalThreadExternalUser = (userShares, userId) => {
  const externalUsers = userShares.filter(
    (userShare) => userShare.userId !== userId,
  );
  return externalUsers?.[0];
};

export const internalThreadExternalUserName = (userShares, userId) => {
  const externalUser = findInternalThreadExternalUser(userShares, userId);
  return contactName(externalUser?.contact);
};

export const isInternalThreadAndOwnedByUser = (thread, userId) => {
  if (thread.type === 'internal' && thread?.userShares?.length) {
    return thread.userShares.some((userShare) => userShare.userId === userId);
  }

  return false;
};

const contactDataCacheAndQuery = ({ client, id }) => {
  const checkCache = () => {
    let entity;
    try {
      const customerContact = client.readFragment({
        id: 'CustomerContact_' + id,
        fragment: CUSTOMER_CONTACT_FRAGMENT,
        fragmentName: 'CustomerContactFragment',
      });
      if (customerContact !== null && customerContact.id !== undefined) {
        entity = customerContact;
      }
    } catch (e) {
      // do nothing
    }
    return entity;
  };

  const hardQuery = async () => {
    let entity;
    const result = await client.query({
      query: CUSTOMER_CONTACT_QUERY,
      variables: { id },
      fetchPolicy: 'cache-first',
    });

    if (result?.data?.customerContact) entity = result?.data?.customerContact;
    return entity;
  };

  return [checkCache, hardQuery];
};

const accountDataCacheAndQuery = ({ client, id }) => {
  const checkCache = () => {
    let entity;
    try {
      const customerAccount = client.readFragment({
        id: 'CustomerAccount_' + id,
        fragment: CUSTOMER_ACCOUNT_INFO_FRAGMENT,
        fragmentName: 'CustomerAccountInfoFragment',
      });
      if (customerAccount !== null && customerAccount.id !== undefined) {
        entity = customerAccount;
      }
    } catch (e) {
      // do nothing
    }
    return entity;
  };

  const hardQuery = async () => {
    let entity;
    const result = await client.query({
      query: CUSTOMER_ACCOUNT_QUERY,
      variables: { id },
      fetchPolicy: 'cache-first',
    });

    if (result?.data?.customerAccount) entity = result?.data?.customerAccount;
    return entity;
  };

  return [checkCache, hardQuery];
};

const userDataCacheAndQuery = ({ client, id }) => {
  const checkCache = () => {
    let entity;
    try {
      const user = client.readFragment({
        id: 'User_' + id,
        fragment: USER_FRAGMENT,
        fragmentName: 'UserFragment',
      });
      if (user !== null && user.id !== undefined) {
        entity = user;
      }
    } catch (e) {
      // do nothing
    }
    return entity;
  };

  const hardQuery = async () => {
    let entity;
    const result = await client.query({
      query: USER_QUERY,
      variables: { id },
      fetchPolicy: 'cache-first',
    });

    if (result?.data?.user) entity = result?.data?.user;
    return entity;
  };

  return [checkCache, hardQuery];
};

const groupDataCacheAndQuery = ({ client, id }) => {
  const checkCache = () => {
    let entity;
    try {
      const group = client.readFragment({
        id: 'Group_' + id,
        fragment: GROUP_FRAGMENT,
        fragmentName: 'GroupFragment',
      });
      if (group !== null && group.id !== undefined) {
        entity = group;
      }
    } catch (e) {
      // do nothing
    }
    return entity;
  };

  const hardQuery = async () => {
    let entity;
    const result = await client.query({
      query: GROUP_QUERY,
      variables: { id },
      fetchPolicy: 'cache-first',
    });

    if (result?.data?.group) entity = result?.data?.group;
    return entity;
  };

  return [checkCache, hardQuery];
};

export const queryContactData = async ({ client, id }) => {
  let customer;

  const [checkCache, hardQuery] = contactDataCacheAndQuery({ client, id });

  customer = checkCache();

  if (customer) return customer;

  customer = await hardQuery();

  return customer;
};

export const queryAccountData = async ({ client, id }) => {
  let account;

  const [checkCache, hardQuery] = accountDataCacheAndQuery({ client, id });

  account = checkCache();

  if (account) return account;

  account = await hardQuery();

  return account;
};

export const queryUserData = async ({ client, id }) => {
  let user;

  const [checkCache, hardQuery] = userDataCacheAndQuery({ client, id });

  user = checkCache();

  if (user) return user;

  user = await hardQuery();

  return user;
};

export const queryGroupData = async ({ client, id }) => {
  let group;

  const [checkCache, hardQuery] = groupDataCacheAndQuery({ client, id });

  group = checkCache();

  if (group) return group;

  group = await hardQuery();

  return group;
};

export const queryEntityData = async ({ client, id }) => {
  let entity;

  const [contactCheckCache, contactHardQuery] = contactDataCacheAndQuery({
    client,
    id,
  });
  const [accountCheckCache, accountHardQuery] = accountDataCacheAndQuery({
    client,
    id,
  });
  const [userCheckCache, userHardQuery] = userDataCacheAndQuery({ client, id });
  const [groupCheckCache, groupHardQuery] = groupDataCacheAndQuery({
    client,
    id,
  });

  const contactCacheLookup = contactCheckCache();
  const accountCacheLoopup = accountCheckCache();
  const userCacheLookup = userCheckCache();
  const groupCacheLookup = groupCheckCache();

  entity =
    contactCacheLookup ||
    accountCacheLoopup ||
    userCacheLookup ||
    groupCacheLookup;
  if (entity) return entity;

  entity = await Promise.allSettled([
    contactHardQuery(),
    accountHardQuery(),
    userHardQuery(),
    groupHardQuery(),
  ])
    .then((results) => results.find((result) => !!result.value))
    .then((result) => result?.value);

  return entity;
};

export const parseCorrectAccountInfo = (entity) => {
  const name = accountName(entity);
  const number = accountNumber(entity);

  if (name && number)
    return `${name}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0ACCT# ${number}`;
  if (name) return name;
  if (number) return number;

  return '';
};

export const createFuzzIntervalMs = (minMs, maxMs) => {
  const intervalDiff = maxMs - minMs;
  return Math.round(Math.random() * intervalDiff) + minMs;
};

export const createFuzzIntervalMsFromMins = (minMinutes, maxMinutes) => {
  const minMs = minMinutes * 60 * 1000;
  const maxMs = maxMinutes * 60 * 1000;
  const unroundedInterval = createFuzzIntervalMs(minMs, maxMs);
  return unroundedInterval - (unroundedInterval % 60000);
};

export const sortLabels = (renderLabels, newLabel) => {
  if (newLabel) {
    renderLabels.push(newLabel);
  }

  const separatedColorlessLabels = [];
  const separatedColorLabels = [];

  renderLabels?.forEach((label) => {
    if (label?.color) {
      separatedColorLabels.push(label);
    } else {
      separatedColorlessLabels.push(label);
    }
  });

  const sortedColorlessLabel = separatedColorlessLabels.sort((a, b) => {
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
      return -1;
    }
    if (a.text.toLowerCase() > b.text.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  const sortedColorLabel = separatedColorLabels.sort((a, b) => {
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
      return -1;
    }
    if (a.text.toLowerCase() > b.text.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return [...sortedColorLabel, ...sortedColorlessLabel];
};

export const formatDataTestName = (name) => {
  return name.split(' ').join('-').toLowerCase();
};

/**
---------------------------------------------------
 */

/**
 * WIDGETS
 */

export const WIDGET_TYPE = {
  WEBCONNECT: 'webconnect',
  WEBCHAT: 'webchat',
};

/**
---------------------------------------------------
 */

/**
 * DASHBOARDS
 */

// function to sort and color thread counts by time period for MultiColorBar
export const sortedGroupThreadsByTime = (group, colors) => {
  const currentTime = new Date(new Date().toISOString());

  const barData = [
    { time: '< 5 minutes', count: 0, color: colors[0] },
    { time: '< 1 hour', count: 0, color: colors[1] },
    { time: '< 4 hours', count: 0, color: colors[2] },
    { time: '4+ hours', count: 0, color: colors[3] },
    { time: '8+ hours', count: 0, color: colors[4] },
  ];

  group.rawThreadData?.forEach((data) => {
    const timeStamp = new Date(data.insertedAt);
    const differenceFromNow = currentTime.getTime() - timeStamp.getTime();

    switch (true) {
      case differenceFromNow < FIVE_MIN:
        barData[0].count += 1;
        break;
      case differenceFromNow < ONE_HOUR:
        barData[1].count += 1;
        break;
      case differenceFromNow < FOUR_HOURS:
        barData[2].count += 1;
        break;
      case differenceFromNow >= FOUR_HOURS && differenceFromNow < EIGHT_HOURS:
        barData[3].count += 1;
        break;
      default:
        barData[4].count += 1;
        break;
    }
  });
  return barData;
};

export const DEFAULT_DASHBOARD_WIDGET_ROW_COUNT = 5;
export const DEFAULT_DASHBOARD_FLYOUT_ROW_COUNT = 100;
export const DEFAULT_DASHBOARD_UNCLAIMED_BY_TIME_COUNT = 1;
export const DEFAULT_DASHBOARD_TABLE_ROW_COUNT = 25;

// Get's highest thread count from all groups or user
// API returns the highest thread count in the first index
export const getOpenThreadCountUpperBound = (openThreadCount) =>
  openThreadCount?.counts[0]?.numOpenThreads || 0;
export const getUnclaimedThreadCountUpperBound = (openThreadCount) =>
  openThreadCount?.counts[0]?.numUnclaimedThreads || 0;

// Dashboards Group Selector

export const SORT_DIRECTION = {
  ascending: 'ASC',
  descending: 'DESC',
};

export const sortArray = (array, sortDirection) =>
  [...array].sort((a, b) => {
    if (sortDirection === SORT_DIRECTION.ascending) {
      return a.name.localeCompare(b.name);
    }
    return b.name.localeCompare(a.name);
  });

// Blocked channels helpers

/**
 * Checks if the provided channel is a blocked SMS channel.
 *
 * @param {object} contact - The contact to check for blocked channels.
 * @param {array} contact.blockedChannels - The list of blocked channels for the contact.
 * @returns {boolean} - Returns true if the contact is blocked SMS channel, false otherwise.
 */
export const hasSMSBlocked = (contact) =>
  ['ALL', 'PHONE'].some((channel) =>
    contact?.blockedChannels?.includes(channel),
  );

/**
 * Checks if the provided channel is a blocked SMS channel.
 *
 * @param {object} contact - The contact to check for blocked channels.
 * @param {array} contact.blockedChannels - The list of blocked channels for the contact.
 * @returns {boolean} - Returns true if the contact is blocked for EMAIL channel, false otherwise.
 */
export const hasEmailBlocked = (contact) =>
  ['ALL', 'EMAIL'].some((channel) =>
    contact?.blockedChannels?.includes(channel),
  );

/**
 * Checks if the provided channel is a blocked SMS channel.
 *
 * @param {object} contact - The contact to check for blocked channels.
 * @param {array} contact.blockedChannels - The list of blocked channels for the contact.
 * @returns {boolean} - Returns true if the contact is blocked FAX channel, false otherwise.
 */
export const hasFaxBlocked = (contact) =>
  ['ALL', 'FAX'].some((channel) => contact?.blockedChannels?.includes(channel));

/**
 * Types of automated rule filters.
 *
 * @readonly
 * @enum {string}
 */
export const RULE_FILTER_TYPES = {
  REGION_OR_GROUP: 'region_or_group',
  THREADS_BY_TYPE: 'threads_by_type',
  COMPANY: 'company', // This has to be updated with the exact BE value in the future because is not already built
  LABEL: 'label', // This has to be updated with the exact BE value in the future because is not already built
  USER: 'claimed_by',
  CONTACT_LABEL: 'contact_label',
  OPT_IN_STATUS: 'opt_in_status_is',
  LAST_OPT_IN_REQUEST: 'last_opt_in_request',
};

/**
 * Types of automated rule actions.
 *
 * @readonly
 * @enum {string}
 */
export const RULE_ACTION_TYPES = {
  ROUTE_TO_GROUP: 'route_to_group',
  ROUTE_TO_USER: 'route_to_user',
  SEND_MESSAGE: 'send_message',
  CLOSE_THREADS: 'close_threads',
  SEND_OPT_IN_REQUEST: 'send_opt_in_request',
};

/**
 * Types of automated rule triggers.
 *
 * @readonly
 * @enum {string}
 */
export const RULE_TRIGGERS_TYPES = {
  INACTIVE_THREAD: 'inactive_thread',
  UNCLAIMED_THREAD: 'unclaimed_thread', // This has to be updated with the exact BE value in the future because is not already built
  RECEIVED_THREAD: 'thread_received',
  CLAIMED_THREAD: 'thread_claimed',
  CLOSED_THREAD: 'thread_closed',
};

export const OPERATORS = {
  ONE_OF: 'includes',
  NOT_ONE_OF: 'excludes',
  EQUALS: 'equals',
  NOT_EQUALS: 'not_equals',
  IS_WITHIN: 'is_within',
  IS_NOT_WITHIN: 'is_not_within',
};

export const RANGE_OPTION_TYPES = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
};

export const VALIDATION_ERRORS = {
  REQUIRED: 'required',
};

export const RULE_DEFINITIONS = {
  ACTIONS: RULE_ACTION_TYPES,
  FILTERS: RULE_FILTER_TYPES,
  TRIGGERS: RULE_TRIGGERS_TYPES,
};

/**
 * Array of single value comparisons.
 *
 * @type {Array<RULE_FILTER_TYPES>}
 */
export const SINGLE_VALUE_COMPARISONS = [
  RULE_FILTER_TYPES.THREADS_BY_TYPE,
  RULE_FILTER_TYPES.COMPANY,
  RULE_FILTER_TYPES.USER,
  RULE_FILTER_TYPES.OPT_IN_STATUS,
];

/**
 * Array of multi-value comparisons.
 *
 * @type {Array<RULE_FILTER_TYPES>}
 */
export const MULTI_VALUE_COMPARISONS = [
  RULE_FILTER_TYPES.CONTACT_LABEL,
  RULE_FILTER_TYPES.REGION_OR_GROUP,
];

/**
 * Array of range value comparisons.
 *
 * @type {Array<RULE_FILTER_TYPES>}
 */
export const RANGE_VALUE_COMPARISONS = [RULE_FILTER_TYPES.LAST_OPT_IN_REQUEST];

export const TYPE_ONLY_ACTIONS = [RULE_ACTION_TYPES.CLOSE_THREADS];

/**
 * Returns a copy of the RULE_FILTER_TYPES object, excluding the specified values.
 *
 * @param {Array<RULE_FILTER_TYPES>} valuesToExclude - An array of values to exclude from the object.
 * @returns {Object} - A copy of the RULE_FILTER_TYPES object with the requested value keys removed.
 */
export const getRuleFilterTypesExcluding = (valuesToExclude) => {
  const filteredTypes = { ...RULE_FILTER_TYPES };

  valuesToExclude.forEach((value) => {
    Object.keys(filteredTypes).forEach((key) => {
      if (filteredTypes[key] === value) {
        delete filteredTypes[key];
      }
    });
  });

  return filteredTypes;
};

/**
 * Builds a rule filter object.
 *
 * @param {string | null} filterType - The type of filter.
 * @param {string | null} filterComparison - The comparison operator for the filter.
 * @param {string | Array<Object> | null} filterValue - The value to filter by.
 * @returns {{ filterType: string | null, filterComparison: string | null, filterValue: string | Array<Object> | null }} - The rule filter object.
 */
export const buildRuleFilter = (
  filterType = null,
  filterComparison = null,
  filterValue = null,
) => ({
  filterType,
  filterComparison,
  filterValue,
});

/**
 * Builds a rule action object.
 *
 * @param {string | null} actionType - The type of action.
 * @param {*} actionValue - The value of the action.
 * @returns {{ actionType: string | null, actionValue: *, isTypeOnly: boolean }} - The rule action object.
 */
export const buildRuleAction = (actionType = null, actionValue = null) => ({
  actionType,
  actionValue,
  isTypeOnly: TYPE_ONLY_ACTIONS.includes(actionType),
});

/**
 * Types of template channel types.
 *
 * @readonly
 * @enum {string}
 */
export const TEMPLATE_CHANNEL_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  BTM: 'BROADCAST_TEXT_MESSAGE',
  BOTH: 'BOTH', // We should really change this to ALL
};

/**
 * Transforms a string or object error code into a translated error message.
 *
 * @param {string | object} errorType - The `VALIDATION_ERRORS` type.
 * @param {object} errorMessages - The object containing the translated error messages.
 * @returns {string} The validation error message.
 */
export const getValidationError = (errorType, errorMessages) => {
  if (!errorType || !errorMessages) {
    return '';
  }

  if (errorType === VALIDATION_ERRORS.REQUIRED) {
    return errorMessages[VALIDATION_ERRORS.REQUIRED];
  }

  if (typeof errorType === 'object') {
    const errorCopy = { ...errorType };

    Object.keys(errorCopy).forEach((key) => {
      if (errorCopy[key] === VALIDATION_ERRORS.REQUIRED) {
        errorCopy[key] = errorMessages[VALIDATION_ERRORS.REQUIRED];
      }
    });

    return errorCopy;
  }

  return errorType;
};

/**
 * Formats the channel label based on the provided channel object.
 * If the channel has an SMS configuration with a phone number, the label will include the group name and formatted phone number.
 * If the channel does not have an SMS configuration, the label will only include the group name.
 *
 * @param {Object} channel - The channel object to format the label for.
 * @returns {string} The formatted channel label.
 */
export const formatChannelLabel = (channel) => {
  if (channel?.smsConfig?.phoneNumber) {
    return `${channel?.group?.name}, ${formatPhoneNumber(
      channel?.smsConfig?.phoneNumber,
    )}`;
  }

  return `${channel?.group?.name}`;
};

/**
 * @typedef {string} SMSStatus
 * @enum {SMSStatus}
 */
export const SMS_STATUS = {
  landline: 'SUSPECTED_LANDLINE',
  not_in_service: 'NOT_IN_SERVICE',
};

/**
 * Retrieves the SMS status alert message based on the provided SMS status and i18n object.
 * @param {Array} smsStatus - The SMS status array.
 * @param {Object} i18n - The i18n object for translation.
 * @returns {string} - The SMS status alert message.
 */

export const getSmsStatusAlertMessage = (smsStatus, i18n) => {
  if (smsStatus.includes(SMS_STATUS.not_in_service))
    return i18n.t('customers-ContactCard-alertNotInService');
  if (smsStatus.includes(SMS_STATUS.landline))
    return i18n.t('customers-ContactCard-alertLandline');
  return '';
};
