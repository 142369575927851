import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  type LexicalEditor,
} from 'lexical';

/**
 * Appends an email signature to the editor.
 *
 * @param {LexicalEditor} editor - The LexicalEditor instance.
 * @param {string} emailSignature - The email signature to append.
 */

const appendEmailSignature = (
  editor: LexicalEditor,
  emailSignature: string
) => {
  // Inside the `editor.update` you can use special $ prefixed helper functions.
  // These functions cannot be used outside the closure, and will error if you try.
  // (If you're familiar with React, you can imagine these to be a bit like using a hook
  // outside of a React function component).
  editor.update(() => {
    // Get the RootNode from the EditorState
    const root = $getRoot();

    // Create a new ParagraphNode
    const paragraphNode = $createParagraphNode();

    // Create a new TextNode
    const textNode = $createTextNode(emailSignature);

    // Append the text node to the paragraph
    paragraphNode.append(textNode);

    // Finally, append the paragraph to the root
    root.append(paragraphNode);
  });
};

export default appendEmailSignature;
