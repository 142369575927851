import React, { useState } from 'react';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useGetFeedback, useUpdateSelectedGroups } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import {
  contactName,
  formatPhoneNumber,
  parseCorrectAccountInfo,
} from 'client-lib/src/lib/utils/helpers';
import { EntityCard, Text, IconButton, Button } from '../../../elements';
import ChipLabel from '../../../elements/Chip/ChipLabel';
import LABEL_THEMES from '../../../styles/themes/library/label';
import {
  CONTACT_LABEL_COLORS,
  getRatingChipLabel,
  getResponseMessage,
  getResponseRating,
} from '../shared';
import { setDefaultContact } from '../../../actions/uploadModal';
import type { RATING_TYPES, Match } from '../types';
import {
  CardHeaderContainer,
  CloseButtonContainer,
  FeedbackDetailsContainer,
  FlexData,
  getEntityCardStyle,
  getTextSize,
  getTextStyles,
  TextContainer,
  ButtonContainer,
} from './styles';
import { setActiveCreateThreadSection } from '../../../actions/general';
import { setThreadsActiveGroupIds } from '../../../actions/session';
import type { AppState } from '../../../utils/helpers/types';
import FeedbackDetailsWarning from './FeedbackDetailsWarning';

interface FeedbackDetailsCardProps {
  dataTestId: string;
}

const FeedbackDetailsCard = ({ dataTestId }: FeedbackDetailsCardProps) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const match: Match = useRouteMatch();
  const [hasOpenThread, setHasOpenThread] = useState(false);
  const threadsActiveGroupIds = useSelector(
    (state: AppState) => state?.session?.threadsActiveGroupIds
  );
  const id = match?.params?.feedbackId;
  const { data } = useGetFeedback({ client, id, fetchPolicy: 'network-only' });
  const selected = data?.getFeedback;
  const { updateSelectedGroupsHander } = useUpdateSelectedGroups({
    client,
  });

  const handleReply = () => {
    const isGroupSelected = threadsActiveGroupIds.find(
      (groupId) => groupId === selected?.thread?.channel?.group?.id
    );
    if (!isGroupSelected) {
      updateSelectedGroupsHander({
        groupIds: [
          selected?.thread?.channel?.group?.id,
          ...threadsActiveGroupIds,
        ],
        onSuccess: (groupIds: string) => {
          dispatch(setThreadsActiveGroupIds(groupIds));
        },
        onError: () => {},
      });
    }

    dispatch(
      setDefaultContact({
        ...selected?.contact,
        feedbackId: selected?.id,
      })
    );
    dispatch(setActiveCreateThreadSection());
  };

  const history = useHistory();
  const subtext = parseCorrectAccountInfo(selected?.contact);
  const rating = getResponseRating(selected?.responses || []);
  const message = getResponseMessage(selected?.responses || []);

  return (
    <FeedbackDetailsContainer data-testid={dataTestId}>
      <CardHeaderContainer>
        <EntityCard
          customContainerStyle={getEntityCardStyle}
          maintext={
            contactName(selected?.contact) ||
            formatPhoneNumber(selected?.contact?.phoneNumber)
          }
          subtext={subtext}
          avatarChildren={contactName(selected?.contact)}
        />
        <CloseButtonContainer>
          <IconButton
            onClick={() => {
              history.push('/feedback/');
            }}
            noOutline
            size="lg"
          >
            <i className="ri-close-line" />
          </IconButton>
        </CloseButtonContainer>
      </CardHeaderContainer>
      <TextContainer>
        <FlexData>
          <ChipLabel
            dataTestId="feedback-chip-label"
            backgroundColor={CONTACT_LABEL_COLORS[rating as RATING_TYPES]}
            textColor={LABEL_THEMES.LABEL_TEXT}
            size="sm"
          >
            {getRatingChipLabel(rating)}
          </ChipLabel>
          {selected?.completedAt ? (
            <Text customStyle={getTextSize} contrast="low">
              {i18n.strftime(new Date(selected?.completedAt), '%l:%M %p')}
            </Text>
          ) : null}
        </FlexData>
        <FlexData>
          <Text customStyle={getTextStyles}>{message}</Text>
        </FlexData>
        {selected?.contact?.id ? (
          <FeedbackDetailsWarning
            id={selected?.contact?.id}
            setHasOpenThread={setHasOpenThread}
          />
        ) : null}
        <ButtonContainer>
          <Button
            onClick={handleReply}
            data-testid="feedback-reply-button"
            disabled={hasOpenThread}
          >
            {i18n.t('feedback-feedback-reply', {
              defaultValue: 'Reply',
            })}
          </Button>
        </ButtonContainer>
      </TextContainer>
    </FeedbackDetailsContainer>
  );
};

export default FeedbackDetailsCard;
