import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { useApolloClient } from '@apollo/client';
import {
  usePaginated,
  ANNOUNCEMENT_REACHABLE_AUDIENCE_QUERY,
} from 'client-lib';
import Flyout from '../../Flyout2/Flyout.tsx';
import { Loading } from '../../../elements';
import BroadcastContactList from './BroadcastContactList';
import { LoadingWrapper } from '../../Settings/styles';

const BroadcastReachableAudienceFlyout = ({
  isOpen,
  setIsOpen,
  announcementId,
}) => {
  const client = useApolloClient();

  // Broadcast Report Data
  const { handleFetchMore, reachableAudience, loading, pageInfo } =
    usePaginated({
      client,
      query: ANNOUNCEMENT_REACHABLE_AUDIENCE_QUERY,
      queryVariables: {
        announcementId,
      },
      resultsNumber: 30,
      key: 'reachableAudience',
    });

  const handleRequestClose = () => {
    setIsOpen(false);
  };

  return (
    <Flyout
      isOpen={isOpen}
      title={i18n.t('slideouts-GroupMessageRecipients-reachable')}
      onRequestClose={handleRequestClose}
      dataTestId="broadcast-table-flyout"
      buttonLabels={{
        primary: i18n.t('broadcasts-ReachableAudienceModal-close', {
          defaultValue: 'Close',
        }),
      }}
      buttonActions={{
        primary: handleRequestClose,
      }}
      customStyles={{ content: () => `padding-bottom: unset;` }}
    >
      {loading && !reachableAudience ? (
        <LoadingWrapper>
          <Loading size="sm" />
        </LoadingWrapper>
      ) : (
        <BroadcastContactList
          loadMoreRows={handleFetchMore}
          recipientData={{
            reachableAudience: {
              pageInfo,
              edges:
                reachableAudience?.map((contact) => ({ node: { contact } })) ||
                [],
            },
          }}
          clientKey="reachableAudience"
        />
      )}
    </Flyout>
  );
};

BroadcastReachableAudienceFlyout.propTypes = {
  announcementId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default BroadcastReachableAudienceFlyout;
