import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../elements';
import THEMES from '../../styles/themes/app';

const StockResponsesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  width: 100%;
  pointer-events: none;

  > * {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

const buttonCustomStyle = (props) => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
  background-color: ${THEMES.BACKGROUND_PRIMARY(props)};
  pointer-events: auto;

  &:hover { background-color: ${THEMES.BACKGROUND_SECONDARY(props)}; }

  @media print {display: none;}
`;

const StockMessages = ({ handleSelectStockResponse }) => {
  const stockMessagesConfig = useSelector(
    (state) => state?.session?.currentUser?.stockMessagesConfig
  );
  return (
    <StockResponsesWrapper data-testid="stock-messaages-wrapper">
      {stockMessagesConfig?.map((button) => (
        <Button
          type="secondaryLight"
          onClick={(e) => handleSelectStockResponse(e, button.label)}
          customStyle={buttonCustomStyle}
          key={button.key}
        >
          {button.label}
        </Button>
      ))}
    </StockResponsesWrapper>
  );
};

StockMessages.propTypes = {
  handleSelectStockResponse: PropTypes.func.isRequired,
};

export default StockMessages;
