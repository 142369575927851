import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import uniqid from 'uniqid';
import { useSpring, animated } from 'react-spring';
import usePaginated from 'client-lib/src/lib/api/query/usePaginated';
import {
  ALLOWED_FILENAME_REGEX,
  NOT_ALLOWED_FILENAME_REGEX,
  fileNameWithoutExtension,
  fileExtension,
  isAttachmentFileTypeSupported,
  TEMPLATE_CHANNEL_TYPES,
} from 'client-lib/src/lib/utils/helpers';
import {
  CUSTOMERS_WITH_ANNOUNCEMENT_REFERENCE_QUERY,
  useCustomerQuery,
} from 'client-lib';
import autosize from 'autosize';
import GroupMessagingTemplate from '../GroupMessageTemplate';
import SaveAndExit from './subComponents/SaveAndExit';
import EmojiMenu from '../../../Threads/EmojiMenu';
import FileReaderInput from '../../../Inputs/FileReaderInput';
import { openSnackbar } from '../../../../actions/general';
import {
  Heading2,
  Text,
  TextArea,
  IconLabel,
  Loading,
  InputError,
} from '../../../../elements';
import THEMES from '../../../../styles/themes/app';
import GroupMessagePreview from './subComponents/GroupMessagePreview.tsx';
import { CreateFormAttachment } from '../../../FileUpload/CreateFormAttachment';
import InsertLink from '../../../Modals/InsertLink';
import TextInput from '../../../../elements/TextInput/TextInput';
import CreateNewTemplateFlyout from './subComponents/CreateNewTemplateFlyout';
import TemplateModal from '../../../Modals/TemplateModal';

// const TOTAL_CHAR_LIMIT = 140;
const MESSAGE_CHAR_LIMIT = 1500;

const SharableHorizontalSpace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

// const Error = styled(Text)`
//   margin-right: 8px;
// `;

const SubheaderWrapper = styled.div`
  margin-bottom: 24px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 8px;
`;

export const PageError = styled.div`
  position: absolute;
  color: ${THEMES.THEME_RED};
  top: -15px;
  font-size: 1rem;
`;

const MessageAreaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const MainMessageContainer = styled.div`
  flex: 1;
`;

const EditabledMessageContainer = styled.div`
  width: 600px;
  position: relative;
  margin: auto;
`;

const PreviewContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_TERTIARY};
  position: relative;
  min-height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const AttachmentButtonWrap = styled.div``;

const FileReaderInputWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
  margin: 8px 0;
  display: flex;
`;

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;
  margin-top: 12px;
`;

const LoadingWrapper = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
`;

const ReportLargeContainter = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const AttachmentTypes = [
  '.jpg',
  '.jpeg',
  'image/jpeg',
  '.png',
  'image/png',
  '.gif',
  'image/gif',
  '.pdf',
  'application/pdf',
  '.doc',
  'application/msword',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls',
  'application/excel',
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

// const textStopCopy = '\n\nText STOP to opt out';

const GroupMessageContent = ({
  closeWizard,
  setWizardPage,
  wizardState,
  mutations,
  wizardGlobalProps,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { announcementId } = wizardState;
  const { updateAnnouncementMutation } = mutations;
  const { data } = wizardGlobalProps.announcementQuery;
  const { name: accountName } = wizardGlobalProps.accountData.account;
  const customerIds = wizardGlobalProps.customerIds;
  const senderGroupId =
    wizardGlobalProps?.channelsData?.channels?.[0]?.group?.id;
  const groupIds = useSelector(
    (state) => state?.session?.currentUser?.groupIds
  );
  const [insertLinkOpen, setInsertLinkOpen] = useState(false);
  const [openCreateNewTemplateFlyout, setCreateNewTemplateFlyout] =
    useState(false);
  const [selectTemplateOpen, setSelectTemplateOpen] = useState(false);
  const [userProvidedLink, setUserProvidedLink] = useState(
    data?.announcement?.announcement?.link ?? ''
  );

  const subject = data?.announcement?.announcement?.subject;
  const text = data?.announcement?.announcement?.text;
  const sendStartedAt = data?.announcement?.announcement.sendStartedAt;
  const announcementType = data?.announcement?.announcement?.type;

  const announcementAttachment =
    data?.announcement?.announcement?.attachments?.[0];
  const formattedAnnouncementAttachment = announcementAttachment
    ? {
        ...announcementAttachment,
        type: announcementAttachment.mimeType,
        previouslyAttachedToAnnouncement: true,
      }
    : null;

  // const prependedText = `${accountName}: `;
  // const dynamicCharLimit =
  //   TOTAL_CHAR_LIMIT - byteCount(prependedText) - byteCount(textStopCopy);

  const initialMessageVal = text || '';

  const messageArea = useRef();

  const [messageVal, setMessageVal] = useState(initialMessageVal);

  // const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState(formattedAnnouncementAttachment);
  const [sendAttachmentAsLink, setSendAttachmentAsLink] = useState(
    announcementAttachment?.method === 'LINK'
  );
  const [attachmentBiggerThanPreview, setAttachmentBiggerThanPreview] =
    useState(false);
  const [pageError, setPageError] = useState('');
  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const messageValTooLong = messageVal?.length > MESSAGE_CHAR_LIMIT;

  useEffect(() => {
    if (pageError) setPageError('');
  }, [messageVal, attachment?.originalFilename]);

  useEffect(() => {
    if (attachment) {
      if (attachment?.size <= 500000) {
        setAttachmentBiggerThanPreview(false);
      } else {
        setAttachmentBiggerThanPreview(true);
      }
    }

    if (messageArea?.current) {
      autosize(messageArea.current);
    }
  }, []);

  useEffect(() => {
    if (messageArea?.current) {
      autosize.update(messageArea.current);
    }
  }, [messageVal]);

  // useEffect(() => {
  //   if (byteCount(messageVal) > dynamicCharLimit || !!attachment) {
  //     setError(i18n.t('announcements-GroupMessageContent-increasedCredits'));
  //   } else {
  //     setError('');
  //   }
  // }, [messageVal, attachment?.id]);

  const { customersWithAnnouncementReference: customers } = usePaginated({
    client,
    query: CUSTOMERS_WITH_ANNOUNCEMENT_REFERENCE_QUERY,
    queryVariables: {
      announcementId,
      groupIds,
    },
    resultsNumber: 1,
    key: 'customersWithAnnouncementReference',
    skip: customerIds?.length,
  });

  const { customerContactQueryData: custData } = useCustomerQuery({
    client,
    customerContactId: customerIds?.[0] || customers?.[0]?.id,
  });

  const templatePreviewVal = useMemo(() => {
    if (custData) {
      return messageVal
        .replaceAll('{FIRST_NAME}', custData.customerContact?.firstName || ' ')
        .replaceAll('{LAST_NAME}', custData.customerContact?.lastName || ' ')
        .replaceAll(
          '{COMPANY_NAME}',
          custData.customerContact?.account?.name || ' '
        )
        .replaceAll('{ACCOUNT_NAME}', accountName)
        .replaceAll(
          '{GROUP_NAME}',
          wizardGlobalProps.channelsData.channels[0]?.group.name
        );
    }

    return messageVal;
  }, [custData, messageVal, wizardGlobalProps]);

  const updateAnnouncement = async ({ onUpdateSuccess, onUpdateFailure }) => {
    if (messageValTooLong) return;

    const onMutationFailure = () => {
      onUpdateFailure?.();
      setPageError(i18n.t('slideouts-GroupMessageName-genericError'));
    };

    setLoading(true);

    let attachments = [];
    if (attachment !== null) {
      attachments = [
        { ...attachment, method: sendAttachmentAsLink ? 'LINK' : 'EMBED' },
      ];
      delete attachments[0].type;
      delete attachments[0].id;
    }

    const input = {
      id: announcementId,
      text: messageVal,
      link: userProvidedLink,
      attachments,
    };

    if (attachment?.previouslyAttachedToAnnouncement) delete input.attachments;

    const { data } = await updateAnnouncementMutation({
      variables: { input },
    }).catch((e) => {
      setLoading(false);
      console.error(e);
      onMutationFailure();
    });

    setLoading(false);

    if (data?.updateAnnouncement?.errors) {
      onMutationFailure();
    } else {
      onUpdateSuccess?.();
    }
  };

  const handleEmojiSelection = (emoji) => {
    const selectionStart = messageArea.current.selectionStart; // cursor group in input
    const beforeSelection = messageArea.current.value.slice(0, selectionStart);
    const afterSelection = messageArea.current.value.slice(selectionStart);
    setMessageVal(`${beforeSelection}${emoji.native} ${afterSelection}`);
    setEmojiMenuOpen(false);

    setTimeout(() => messageArea?.current?.focus?.(), 0);
  };

  const handleFileReaderInputChange = (e) => {
    const originalFile = e.target.files[0];
    validateAttachment(originalFile);
    e.target.value = null;
  };

  const handleSetAttachment = ({
    originalFile,
    originalFilename = originalFile.name,
    handleAttachmentMidsize,
  }) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
      };
      if (handleAttachmentMidsize) {
        handleAttachmentMidsize(originalFile);
      }
      setAttachment(attachment);
    };
    reader.readAsDataURL(originalFile);
  };

  const handleAttachmentMidsize = (originalFile) => {
    if (originalFile.size > 500000) {
      setAttachmentBiggerThanPreview(true);
    }
    if (originalFile.size <= 500000) {
      setAttachmentBiggerThanPreview(false);
    }
  };

  const validateAttachment = (originalFile) => {
    const filename = fileNameWithoutExtension(originalFile.name);
    const extension = `.${fileExtension(originalFile.name)}`;

    if (!isAttachmentFileTypeSupported(originalFile.name)) {
      setPageError(i18n.t('slideouts-CreateThread-unsupportedFile'), 'error');
      setAttachmentBiggerThanPreview(false);
    } else if (originalFile.size > 5000000) {
      setPageError(i18n.t('slideouts-CreateThread-fileTooLarge'), 'error');
    } else if (!filename.match(ALLOWED_FILENAME_REGEX)) {
      const removedSpecialCharacters = filename.replace(
        NOT_ALLOWED_FILENAME_REGEX,
        ''
      );
      const adaptedFilename = `${removedSpecialCharacters}${extension}`;
      handleSetAttachment({
        originalFile,
        originalFilename: adaptedFilename,
        handleAttachmentMidsize,
      });
    } else {
      handleSetAttachment({ originalFile, handleAttachmentMidsize });
    }
  };

  const renderAttachmentSection = () =>
    attachment !== null ? (
      <FullFormRow>
        <CreateFormAttachment
          attachments={[attachment]}
          onClose={() => {
            setAttachmentBiggerThanPreview(false);
            setAttachment(null);
          }}
          sendAsLink={sendAttachmentAsLink}
          onMethodChange={() => setSendAttachmentAsLink(!sendAttachmentAsLink)}
          disabled={!!sendStartedAt}
        />
      </FullFormRow>
    ) : null;

  const nextStep = () => {
    updateAnnouncement({
      onUpdateSuccess: () => setWizardPage('GroupMessageOverviewCombined'),
    });
  };

  const onCloseWizard = () =>
    updateAnnouncement({
      onUpdateSuccess: () =>
        dispatch(
          openSnackbar(
            i18n.t('slideouts-GroupMessageAudience-successfullySaved')
          )
        ),
      onUpdateFailure: () =>
        dispatch(
          openSnackbar(i18n.t('slideouts-GroupMessageContent-error'), 'error')
        ),
    });

  const messageButtonEnabled =
    (messageVal || attachment?.originalFilename) && !messageValTooLong;

  const previewWidth = messageButtonEnabled || !!sendStartedAt ? '30%' : '0%';
  const animation = useSpring({
    width: previewWidth,
    marginTop: -40,
  });

  const imageUrlToFile = async (imageUrl, filename = 'image') => {
    if (!imageUrl) {
      return null;
    }

    const response = await fetch(imageUrl);

    if (!response.ok) {
      return null;
    }

    const blob = await response.blob();
    const file = new File([blob], `${filename}.${blob.type.split('/')[1]}`, {
      type: blob.type,
    });

    return file;
  };

  const addTemplateAttachment = (attachment) => {
    if (!attachment?.id || !attachment?.originalFilename) {
      setAttachment(null);

      return;
    }

    const image = `/attachments/${attachment.id}/original/${attachment.originalFilename}`;

    imageUrlToFile(image, attachment.originalFilename).then((file) => {
      handleSetAttachment({
        originalFile: file,
        originalFilename: attachment.originalFilename,
        handleAttachmentMidsize,
      });
    });
  };

  const setTemplateMessage = (message) => {
    setMessageVal(message);
  };

  return (
    <GroupMessagingTemplate
      title={subject}
      headerRightElement={
        <SaveAndExit
          closeWizard={closeWizard}
          onClose={onCloseWizard}
          sendStartedAt={!!sendStartedAt}
        />
      }
      hideTemplateButton={false}
      templateButtonAction={() => setCreateNewTemplateFlyout(true)}
      showFooter
      darkFooter
      continueButtonText={i18n.t(
        'slideouts-GroupMessageRecipients-saveContinue'
      )}
      continueButtonDisabled={!messageButtonEnabled}
      continueButtonAction={nextStep}
      hideContinueButton={!!sendStartedAt}
      backButtonAction={() => setWizardPage('GroupMessageOverviewCombined')}
    >
      {loading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <MessageAreaContainer>
          <MainMessageContainer data-testid="group-message-content-step">
            <EditabledMessageContainer>
              {pageError && <PageError>{pageError}</PageError>}
              <HeaderWrapper>
                <Heading2>
                  {i18n.t('slideouts-GroupMessageContent-message')}
                </Heading2>
              </HeaderWrapper>
              <SubheaderWrapper>
                <Text>
                  {!sendStartedAt
                    ? i18n.t('slideouts-GroupMessageContent-whatMessageSay')
                    : i18n.t('slideouts-GroupMessageContent-messageSent')}
                </Text>
              </SubheaderWrapper>

              <div style={{ position: 'relative' }}>
                <TextArea
                  label={i18n.t('slideouts-GroupMessageOverview-messageText')}
                  customTextAreaStyle={() => `
                    padding-left: 14px; line-height: 18px;
                    max-height: calc(100vh - 260px);
                  `}
                  disabled={!!sendStartedAt}
                  value={messageVal}
                  onChange={(e) => setMessageVal(e.target.value)}
                  ref={messageArea}
                  autoFocus
                  rows={8}
                  dataTestId="group-message-content-textarea"
                  hideBottomSpace
                  error={messageValTooLong}
                />
              </div>

              {userProvidedLink !== '' && (
                <div style={{ position: 'relative', marginTop: '8px' }}>
                  <TextInput
                    label=""
                    value={userProvidedLink}
                    onChange={(e) =>
                      e.target.value === ''
                        ? setUserProvidedLink(e.target.value)
                        : null
                    }
                    dataTestId="group-message-content-link-input"
                    hasClearButton
                    hideBottomSpace
                    readOnly
                  />
                </div>
              )}

              <TemplateModal
                channelType={TEMPLATE_CHANNEL_TYPES.BTM}
                selectTemplate={setTemplateMessage}
                selectTemplateWithKeys
                addAttachment={addTemplateAttachment}
                addLink={setUserProvidedLink}
                isOpen={selectTemplateOpen}
                onRequestClose={() => setSelectTemplateOpen(false)}
                senderGroupId={senderGroupId}
                top="56px"
                bodyHeight={userProvidedLink === '' ? '320px' : '360px'}
                titleOverride={i18n.t(
                  'broadcasts-groupMessage-broadcastTemplates'
                )}
                isFixed
                removeAttachment
              />

              <SharableHorizontalSpace>
                <SharableHorizontalSpace>
                  <ButtonWrapper>
                    <IconLabel
                      disabled={!!sendStartedAt}
                      onClick={() =>
                        !sendStartedAt ? setSelectTemplateOpen(true) : null
                      }
                      title={i18n
                        .t('settings-AddOrEditTemplate-templates')
                        .toLocaleLowerCase()}
                      contrast="highColor"
                      dataTestId="group-message-content-use-template"
                    >
                      <i className="ri-file-list-3-line" />
                    </IconLabel>
                    <AttachmentButtonWrap>
                      <FileReaderInput
                        disabled={!!sendStartedAt}
                        name="modalAttachmentInput"
                        WrapperComponent={FileReaderInputWrapper}
                        handleFileReaderInputChange={
                          handleFileReaderInputChange
                        }
                        accept={AttachmentTypes.join(',')}
                      >
                        <IconLabel
                          htmlFor="modalAttachmentInput"
                          contrast="highColor"
                          disabled={!!sendStartedAt}
                        >
                          <i className="ri-attachment-2" />
                        </IconLabel>
                      </FileReaderInput>
                    </AttachmentButtonWrap>
                    <EmojiMenu
                      labelType="grey"
                      handleEmojiSelection={handleEmojiSelection}
                      open={emojiMenuOpen}
                      onClickOutside={() => setEmojiMenuOpen(false)}
                    >
                      {({ emojiTouchTap }) => (
                        <IconLabel
                          onClick={(e) => {
                            if (!sendStartedAt) {
                              emojiTouchTap(e);
                              setEmojiMenuOpen(true);
                            }
                          }}
                          title={i18n.t('slideouts-CreateThread-insertEmoji')}
                          htmlFor="emojiInput"
                          contrast="highColor"
                          disabled={!!sendStartedAt}
                        >
                          <i className="ri-emotion-line" />
                        </IconLabel>
                      )}
                    </EmojiMenu>
                    <IconLabel
                      onClick={() => {
                        if (userProvidedLink === '' && !sendStartedAt) {
                          setInsertLinkOpen(true);
                        }
                      }}
                      title={i18n.t('slideouts-PaymentForm-insertLink')}
                      contrast="highColor"
                      disabled={!!sendStartedAt || userProvidedLink !== ''}
                      dataTestId="group-message-content-insert-link"
                    >
                      <i className="ri-links-line" />
                    </IconLabel>
                    {attachmentBiggerThanPreview && !pageError && (
                      <ReportLargeContainter>
                        <Text contrast="red">
                          {i18n.t('reports-Reports-tooLarge')}
                        </Text>
                      </ReportLargeContainter>
                    )}
                  </ButtonWrapper>
                  {messageValTooLong && (
                    <InputError
                      error={i18n.t('broadcasts-groupMessage-characterLimit', {
                        defaultValue: `A maximum of ${MESSAGE_CHAR_LIMIT} characters is allowed.`,
                        characterLimit: MESSAGE_CHAR_LIMIT,
                      })}
                    />
                  )}
                  {/* {error && (
                    <Error
                      contrast="red"
                      data-testid="group-message-content-error"
                    >
                      {error}
                    </Error>
                  )} */}
                </SharableHorizontalSpace>
                {/* {(messageVal || attachment) && !sendStartedAt ? (
                  <GroupMessageCharCount
                    charLimit={dynamicCharLimit}
                    messageVal={messageVal}
                    messageHasAttachment={!!attachment}
                  />
                ) : null} */}
              </SharableHorizontalSpace>
              {renderAttachmentSection()}
              <br />
              <br />
              <br />
            </EditabledMessageContainer>
          </MainMessageContainer>
          <animated.div style={animation}>
            <PreviewContainer>
              <GroupMessagePreview
                sent={!!sendStartedAt}
                accountName={accountName}
                messageText={templatePreviewVal || messageVal}
                link={userProvidedLink}
                loading={loading || !custData}
                attachment={attachment}
                sendAttachmentAsLink={sendAttachmentAsLink}
                attachmentBiggerThanPreview={attachmentBiggerThanPreview}
                announcementType={announcementType}
              />
            </PreviewContainer>
          </animated.div>
        </MessageAreaContainer>
      )}
      <InsertLink
        open={insertLinkOpen}
        handleOnClose={() => setInsertLinkOpen(false)}
        handleOnConfirm={(value) => {
          setUserProvidedLink(value);
        }}
        value={userProvidedLink}
      />
      {openCreateNewTemplateFlyout && (
        <CreateNewTemplateFlyout
          onClose={() =>
            setCreateNewTemplateFlyout(!openCreateNewTemplateFlyout)
          }
          isOpen={openCreateNewTemplateFlyout}
          messageText={messageVal}
          attachments={attachment}
          link={userProvidedLink}
        />
      )}
    </GroupMessagingTemplate>
  );
};

GroupMessageContent.propTypes = {
  closeWizard: PropTypes.func.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardState: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
};

export default GroupMessageContent;
