import { useState } from "react";
import debounce from "lodash/debounce";
import usePostMessage from "../../../api/mutations/usePostMessage";
import useUploadAttachment from "../attachment/useUploadAttachment";
/*

DOCUMENTATION
  this hook was created to be used for SubmitMessageInput component, but is designed to be flexible enough to be modular. It will keep track of message value
  state, as well as give a handleSubmit function that will call postMessageMutation using the message value.

  args:
    client: (obj) required. Apollo Client instance
    threadId: (str) required. Thread id that the message will be posted to.
    onSubmit: (func) optional. callback that will fire on handleSubmit call.

  returns:
    [0] messageVal (str): state, current message value string
    [1] setMessageVal (func): state setter, sets state for message value
    [2] handleSubmit (func): handles postmessage mutation using current messageVal

*/

const useSubmitMessageInput = ({
  client,
  onSubmit,
  onSuccess,
  onError,
  threadId,
  attachments,
}) => {
  const [handlePostMessage] = usePostMessage({ client });

  const [messageVal, setMessageVal] = useState("");
  const [htmlMessageVal, setHTMLMessageVal] = useState(null);
  const [submittingMessage, setSubmittingMessage] = useState(false);

  const uploadAttachment = useUploadAttachment({ client });

  const handleSubmit = debounce(
    async () => {
      setSubmittingMessage(true);
      let messageAttachments = [];

      if (messageVal === "" && !attachments.length) {
        return;
      } // prevent empty message submissions

      if (attachments.length) {
        const settledResponse = await Promise.allSettled(
          attachments.map((attachment) => uploadAttachment(attachment))
        );

        settledResponse.forEach((resp) => {
          if (resp.status === "rejected") onError(resp.reason);
        });

        messageAttachments = settledResponse
          .filter((resp) => resp.status === "fulfilled")
          .map((resp) => ({
            url: resp.value.url,
            method: "EMBED",
          }));
      }

      await handlePostMessage({
        text: messageVal,
        html: htmlMessageVal,
        threadId,
        attachments: messageAttachments,
        onError,
        onSuccess,
      });

      if (onSubmit) onSubmit();

      setSubmittingMessage(false);
    },
    1000,
    { leading: true }
  );

  return [
    messageVal,
    setMessageVal,
    htmlMessageVal,
    setHTMLMessageVal,
    handleSubmit,
    submittingMessage,
  ];
};

export default useSubmitMessageInput;
