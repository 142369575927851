import React, { useEffect, useRef } from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Heading5 } from '../../elements';
import { copyKeyInArray } from '../../utils/helpers/objectUtils.ts';
import LabelAsyncSelect from '../AsyncSelects/LabelAsyncSelect';

const LabelFormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${(props) => props.marginTop || PROP_DEFAULTS.marginTop};
  margin-bottom: ${(props) => props.marginBottom || PROP_DEFAULTS.marginBottom};
`;

const PROP_DEFAULTS = {
  labelValues: [],
  labelRef: null,
  marginTop: '8px',
  marginBottom: '0px',
  openOnFocus: true,
  openOnInit: false,
};

/**
 * LabelFormInput component.
 *
 * @component
 * @param {Function} handleLabelDropdown - Function to handle label dropdown.
 * @param {Array} [labelValues] - Array of label values.
 * @param {Object} [labelRef] - Reference to the label input element.
 * @param {string} [marginTop] - Margin top value.
 * @param {string} [marginBottom] - Margin bottom value.
 * @param {boolean} [openOnFocus] - Flag indicating if the dropdown should open on focus.
 * @param {boolean} [openOnInit] - Flag indicating if the dropdown should open on initialization.
 * @returns {JSX.Element} LabelFormInput component.
 */
const LabelFormInput = ({
  handleLabelDropdown,
  labelValues,
  labelRef,
  marginTop,
  marginBottom,
  openOnFocus,
  openOnInit,
  ...props
}) => {
  const innerRef = useRef(null);

  useEffect(() => {
    if (openOnInit && innerRef?.current) {
      innerRef.current.focus();
      innerRef.current.openMenu();
    }
  }, [openOnInit]);

  /**
   * Sets the value of the `openAfterFocus` property on the `innerRef` object.
   *
   * @param {boolean} bool - The boolean value to set for `openAfterFocus`.
   */
  const setOpenOnFocus = (bool) => {
    if (innerRef?.current) {
      innerRef.current.openAfterFocus = bool;
    }
  };

  return (
    <LabelFormInputWrapper marginTop={marginTop} marginBottom={marginBottom}>
      <Heading5>
        {i18n.t('settings-Label-labels', {
          defaultValue: 'Labels',
        })}
      </Heading5>
      <LabelAsyncSelect
        dataTestId="label-EditCustomerContactDetails-labelSelector"
        value={copyKeyInArray(labelValues, 'text', 'label')}
        setValue={(ev) => {
          handleLabelDropdown(ev);
        }}
        placeholder={i18n.t('settings-Label-searchLabels', {
          defaultValue: 'Search Labels',
        })}
        onFocus={() => setOpenOnFocus(openOnFocus)}
        isMulti
        ref={labelRef || innerRef}
        {...props}
      />
    </LabelFormInputWrapper>
  );
};

LabelFormInput.propTypes = {
  handleLabelDropdown: PropTypes.func.isRequired,
  labelValues: PropTypes.array,
  labelRef: PropTypes.object,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  openOnFocus: PropTypes.bool,
  openOnInit: PropTypes.bool,
};

LabelFormInput.defaultProps = PROP_DEFAULTS;

export default LabelFormInput;
