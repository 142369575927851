import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import autosize from 'autosize';
import ResizeObserver from 'resize-observer-polyfill';
import {
  useSubmitMessageInput,
  useWatchAndReadMessage,
  useEmailSignature,
  CHANNELS_QUERY,
} from 'client-lib';
import theme from 'styled-theming';
import i18n from 'i18n-js';
import {
  fileExtension,
  isAttachmentFileTypeSupported,
  ALLOWED_FILENAME_REGEX,
  fileNameWithoutExtension,
  NOT_ALLOWED_FILENAME_REGEX,
} from 'client-lib/src/lib/utils/helpers';
import uniqid from 'uniqid';
import StockMessages from './StockMessages';
import EmojiMenu from './EmojiMenu';
import {
  clearAttachments,
  closeAnnotationsModal,
  deleteAttachment,
  openAnnotationsModal,
  pushAttachment,
  setAnnotationAttachmentIndex,
} from '../../actions/uploadModal';
import {
  openSnackbar,
  setPreviousActiveThreadId,
  setSubmitMessageInputHeight,
} from '../../actions/general';
import { IconLabel, TextArea, Button, AttachmentTile } from '../../elements';
import COLORS from '../../styles/colors';
import TemplateModal from '../Modals/TemplateModal';
import EmailSignature from './EmailSignature';
import AnnotationsModal from '../Annotations/AnnotationsModal';
import DragAndDropModal from '../Inputs/DragAndDropModal';
import Carousel from '../../elements/AttachmentTile/Carousel';
import RichTextEditor from '../../elements/RichTextEditor/RichTextEditor.tsx';
import convertLexicalToHtmlAndText from '../../elements/RichTextEditor/utils/convertLexicalToHtmlAndText.ts';
import ChangeFactory, {
  MutationMethod,
} from '../../elements/RichTextEditor/utils/onChangeFactory.ts';
import appendEmailSignatureRichText from '../../elements/RichTextEditor/utils/appendEmailSignature.ts';
import lexicalDeserialization from '../../elements/RichTextEditor/utils/lexicalDeserialization.ts';
import {
  InsertType,
  KeyCombo,
} from '../../elements/RichTextEditor/utils/constants.ts';

const BOX_SHADOW = theme('mode', {
  classic: '0 1px 6px 0 rgba(0, 0, 0, 0.45)',
  light: '0 1px 6px 0 rgba(0, 0, 0, 0.45)',
  dark: '0 0 0 2px #3A3A3A',
  holiday: '0 1px 6px 0 rgba(0, 0, 0, 0.45)',
});
const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.WHITE,
});

export const MAX_COMBINED_FILE_SIZE = 30000000;

export const calcCombinedAttachmentSize = (
  newAttachments,
  currentAttachments
) => {
  const currentSize = currentAttachments
    .map((attachment) => attachment.size)
    .reduce((acc, val) => {
      return val + acc;
    }, 0);

  const newCombinedSize = newAttachments
    .map((newAttachment) => newAttachment.size)
    .reduce((acc, val) => {
      return val + acc;
    }, 0);

  return currentSize + newCombinedSize;
};

const Justified = styled.div`
  display: flex;
`;

// Message input styled-components
const Wrapper = styled.div`
  margin-top: auto;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  width: ${(props) => (props.shrink ? 'calc(100% - 30vw)' : '100%')};
  z-index: 2;
  flex: 1 1 0%;
  box-sizing: border-box;
  pointer-events: none;

  > div.auto-pointer-events {
    pointer-events: auto;
  }

  @media print {
    visibility: hidden;
  }
`;

const AttachmentInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const textareaCustomStyle = (props) => `
  max-height: 400px;
  box-shadow: none;
  border-color: none;
  border-width: 0;
  outline: none;
  background-color: ${BACKGROUND(props)};

  :focus {
    box-shadow: none;
    border-color: none;
    outline: none;
  }
`;

const ContainElements = styled.div`
  border-radius: 4px;
  box-shadow: ${BOX_SHADOW};
  padding: 8px;
  background-color: ${BACKGROUND};
`;

const WidthWrap = styled.div`
  width: 100%;
`;

const LowerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const IconSection = styled.div`
  display: flex;
  align-items: center;
`;

const SubmitMessageInput = ({
  hasUnreadMessage,
  contactId,
  channelType,
  contact,
  groupId,
}) => {
  const match = useRouteMatch();
  const { activeThreadId } = match.params;

  const client = useApolloClient();

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const activeTransferThreadSlideout = useSelector(
    (state) => state?.general?.activeTransferThreadSlideout
  );
  const activeCustomerInfoSlideout = useSelector(
    (state) => state?.general?.activeCustomerInfoSlideout
  );
  const activeForwardMessageSlideout = useSelector(
    (state) => state?.general?.activeForwardMessageSlideout
  );
  const activeMergeCustomerSlideout = useSelector(
    (state) => state?.general?.activeMergeCustomerSlideout
  );
  const senderGroupId = useSelector(
    (state) => state?.session?.threadsActiveGroupIds?.[0]
  );
  const attachments = useSelector((state) => state?.uploadModal?.attachments);

  const annotationsModalOpen = useSelector(
    (state) => state?.uploadModal?.annotationsModalOpen
  );
  const annotationAttachmentIndex = useSelector(
    (state) => state?.uploadModal?.annotationAttachmentIndex
  );
  const ff_rich_text_email_formatting = useSelector(
    (state) =>
      state?.accountData?.account?.ff_rich_text_email_formatting || false
  );

  const [pastedCursorPosition, setPastedCursorPosition] = useState({
    cursorPosition: 0,
    event: null,
  });
  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const messageInputRef = useRef();
  const richTextEditorRef = useRef();

  const enableRichTextEditor =
    ff_rich_text_email_formatting && channelType === 'EMAIL';

  const checkTemplateDisabled =
    !contact ||
    contact?.__typename === 'User' ||
    contact?.__typename === 'Group';

  const resetInitialMessageState = () => {
    setMessageVal('');
    setHTMLMessageVal(null);
    if (enableRichTextEditor) {
      lexicalDeserialization({
        editor: richTextEditorRef.current,
        htmlString: '<p />',
        insertType: InsertType.replaceNodes,
      });
    }

    dispatch(clearAttachments());

    if (messageInputRef.current) {
      autosize(messageInputRef.current);
      focusOnMessageInput();
    }

    if (autoAddSig && channelType === 'EMAIL') {
      if (enableRichTextEditor) {
        handleAddSignature();
      } else {
        // Using append rather than handleAddSignature,
        // as we append does not try to read from message value state
        setMessageVal(appendEmailSignature(''));
        onAddSignature();
      }
    }
  };

  const onSubmit = () => {};

  const onSuccess = () => resetInitialMessageState();

  const onError = (err) => {
    console.error('message failed: ', err);
  };

  const { data: channelsData } = useQuery(CHANNELS_QUERY, {
    client,
    variables: {
      accountId: currentUser?.accountId,
    },
  });

  const maxCombinedFileSizeLimit =
    channelsData?.channels?.find(
      (channel) =>
        channel?.group?.id === groupId &&
        channel?.type.toUpperCase() === channelType.toUpperCase()
    )?.outboundFileSizeLimit || MAX_COMBINED_FILE_SIZE;

  const aerialinkSmsChannels = channelsData?.channels?.filter(
    (channel) =>
      channel?.smsConfig?.smsCarrier === 'AERIALINK' &&
      channel?.type === 'conversations'
  );

  const currentThreadIsAerialink =
    channelType === 'SMS' &&
    aerialinkSmsChannels?.some((channel) => {
      if (channel.group.id === groupId) {
        return true;
      }
      return false;
    });

  const [
    messageVal,
    setMessageVal,
    htmlMessageVal, // eslint-disable-line no-unused-vars
    setHTMLMessageVal,
    handleSubmit,
    submittingMessage,
  ] = useSubmitMessageInput({
    client,
    onSubmit,
    threadId: activeThreadId,
    attachments,
    onError,
    onSuccess,
  });

  useWatchAndReadMessage({
    hasUnreadMessage,
    messageVal,
    threadId: activeThreadId,
    client,
  });

  const { emailSigOn, autoAddSig, handleAddSignature, appendEmailSignature } =
    useEmailSignature({
      client,
      userId: currentUser?.userId,
      messageVal,
      setMessageVal,
      richTextEditorRef,
      setHTMLVal: appendEmailSignatureRichText,
    });

  const focusOnMessageInput = () => {
    if (activeThreadId && messageInputRef.current) {
      messageInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (messageInputRef.current) {
      autosize.update(messageInputRef.current);
    }
  }, [messageVal]);

  // Can't use autosize with the rich text editor
  // Use height of editor container for all resizing
  const richTextResizeHandlerRef = useCallback((node) => {
    if (!node) return;
    dispatch(setSubmitMessageInputHeight(node.getBoundingClientRect().height));
    const resizeObserver = new ResizeObserver(() => {
      dispatch(
        setSubmitMessageInputHeight(node.getBoundingClientRect().height)
      );
    });
    resizeObserver.observe(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // when people paste, we want the cursor to be in the correct place.
    const pcp = pastedCursorPosition;
    if (
      pcp &&
      pcp.event &&
      pcp.event.target &&
      pcp.event.target.setSelectionRange &&
      pcp.cursorPosition
    ) {
      pcp.event.target.setSelectionRange(
        pcp.cursorPosition,
        pcp.cursorPosition
      );
    }
  }, [pastedCursorPosition]);

  const onAddSignature = () => {
    if (!enableRichTextEditor) {
      const selectionStart = messageInputRef.current.selectionStart; // cursor group in input
      const selectionEnd = messageInputRef.current.selectionEnd; // cursor group in input
      setTimeout(() => {
        messageInputRef?.current?.focus();
        messageInputRef?.current?.setSelectionRange?.(
          selectionStart,
          selectionEnd
        );
      }, 0);
    }
  };

  const handleSetAnnotationModalOpen = (open) => {
    const handleOpen = open ? openAnnotationsModal : closeAnnotationsModal;
    dispatch(handleOpen());
  };

  useEffect(() => {
    return () => {
      dispatch(setPreviousActiveThreadId(''));
    };
  }, []);

  // Watch for traverse to different thread or initial load
  useEffect(() => {
    resetInitialMessageState();

    dispatch(setPreviousActiveThreadId(activeThreadId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAddSig, activeThreadId, channelType]);

  const handleSelectStockResponse = (e, label) => {
    setMessageVal(label);
    if (enableRichTextEditor) {
      lexicalDeserialization({
        editor: richTextEditorRef.current,
        htmlString: label,
        insertType: InsertType.replaceNodes,
      });
    }
  };

  const [richTextInitiateSend, setRichTextInitiateSend] = useState(false);
  const handleSubmitRequest = () => {
    if (enableRichTextEditor) {
      const { html, text } = convertLexicalToHtmlAndText(
        richTextEditorRef.current
      );
      setHTMLMessageVal(html);
      setMessageVal(text);
      // Need to wait for state valus to update
      // before sending the mutation
      setRichTextInitiateSend(true);
    } else {
      handleSubmit();
    }
  };
  useEffect(() => {
    if (richTextInitiateSend) {
      handleSubmit();
      setRichTextInitiateSend(false);
    }
    // handleSubmit is debounced, and thus
    // chanes its reference on each render; do not include in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [richTextInitiateSend]);
  const handleEnterSubmit = (e) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      handleSubmitRequest();
    }
  };

  // Need to update the text to enable the send button
  const onRichTextEditorChange = (text) => {
    setMessageVal(text);
  };

  const handleEmojiSelection = (emoji) => {
    if (enableRichTextEditor) {
      lexicalDeserialization({
        editor: richTextEditorRef.current,
        htmlString: emoji.native,
        insertType: InsertType.insertAtSelection,
      });
    } else {
      const selectionStart = messageInputRef.current.selectionStart; // cursor group in input
      const beforeSelection = messageInputRef.current.value.slice(
        0,
        selectionStart
      );
      const afterSelection =
        messageInputRef.current.value.slice(selectionStart);

      setMessageVal(`${beforeSelection}${emoji.native} ${afterSelection}`);

      setTimeout(() => messageInputRef?.current?.focus?.(), 0);
    }
    setEmojiMenuOpen(false);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    e.persist();
    let clipBoardItem = null;
    // If text is just plain text, past it in based on cursor group
    if (e.clipboardData.types.includes('Files')) {
      clipBoardItem = e.clipboardData.items[1]
        ? e.clipboardData.items[1]
        : e.clipboardData.items[0];

      const originalFile = clipBoardItem.getAsFile();
      validateAttachment(originalFile);
    } else {
      clipBoardItem = e.clipboardData.items[0];
      if (
        e.clipboardData.types[0] === 'text/plain' ||
        e.clipboardData.types[0] === 'text/html'
      ) {
        const pastedText = e.clipboardData.getData('Text');
        const selectionStart = e.target.selectionStart; // cursor group in input
        const selectionEnd = e.target.selectionEnd;
        const beforeSelection = e.target.value.slice(0, selectionStart);
        const afterSelection = e.target.value.slice(selectionEnd);
        const cursorPosition = beforeSelection.length + pastedText.length;

        setMessageVal(`${beforeSelection}${pastedText}${afterSelection}`);

        setPastedCursorPosition({ cursorPosition, event: e });
      }
    }
  };

  const validateAttachment = (originalFile) => {
    const filename = fileNameWithoutExtension(originalFile.name);
    const extension = `.${fileExtension(originalFile.name)}`;
    if (!isAttachmentFileTypeSupported(originalFile.name)) {
      const unsupportedTypeMsg = i18n.t(
        'threads-SubmitMessageInput-unsupported'
      );
      dispatch(openSnackbar(unsupportedTypeMsg, 'error'));
    } else if (
      calcCombinedAttachmentSize([originalFile], attachments) >
      maxCombinedFileSizeLimit
    ) {
      const tooLargeMsg = i18n.t('threads-SubmitMessageInput-fileTooLarge');
      dispatch(openSnackbar(tooLargeMsg, 'error'));
    } else if (!filename.match(ALLOWED_FILENAME_REGEX)) {
      const removedSpecialCharacters = filename.replace(
        NOT_ALLOWED_FILENAME_REGEX,
        ''
      );
      const adaptedFilename = `${removedSpecialCharacters}${extension}`;
      handleSetAttachment(originalFile, adaptedFilename);
    } else {
      handleSetAttachment(originalFile);
    }
  };

  const handleSetAttachment = (
    originalFile,
    originalFilename = originalFile.name
  ) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
        size: originalFile.size,
      };
      if (currentThreadIsAerialink && attachments.length) {
        dispatch(deleteAttachment(attachments[0].id));
      }
      dispatch(pushAttachment(attachment));
    };
    reader.readAsDataURL(originalFile);
  };

  const handlePushAttachemnt = (attachment) => {
    dispatch(pushAttachment(attachment));
  };

  const handleSaveAnnotation = (uri) => {
    const activeAttachment = attachments[annotationAttachmentIndex];
    const newAttachment = {
      data: uri,
      originalFilename: activeAttachment.originalFilename,
      type: activeAttachment.type,
      // id: attachment.id,
      id: uniqid(), // creating a new id on annotation save, so that the image for sure re-renders when new state set.
      size: activeAttachment.size,
    };

    dispatch(deleteAttachment(activeAttachment.id));
    dispatch(pushAttachment(newAttachment));
    handleSetAnnotationModalOpen(false);
  };

  const handleTemplateApplication = (templateText) => {
    setMessageVal(templateText);
    if (enableRichTextEditor) {
      lexicalDeserialization({
        editor: richTextEditorRef.current,
        htmlString: templateText,
        insertType: InsertType.replaceNodes,
      });
    }
  };

  return (
    <Justified data-testid="submit-message-input">
      <Wrapper
        shrink={
          activeTransferThreadSlideout ||
          activeCustomerInfoSlideout ||
          activeForwardMessageSlideout ||
          activeMergeCustomerSlideout
        }
      >
        <DragAndDropModal />

        <AnnotationsModal
          open={annotationsModalOpen}
          setOpen={handleSetAnnotationModalOpen}
          attachment={attachments[annotationAttachmentIndex]}
          handleSave={handleSaveAnnotation}
        />

        <StockMessages handleSelectStockResponse={handleSelectStockResponse} />
        <WidthWrap className="auto-pointer-events">
          <ContainElements
            ref={richTextResizeHandlerRef}
            data-testid="submit-message-input-container"
          >
            {enableRichTextEditor ? (
              <RichTextEditor
                defaultText={i18n.t('threads-SubmitMessageInput-placeholder')}
                onError={(error) => console.error(error)}
                onChangeListeners={[
                  ChangeFactory(MutationMethod.text, onRichTextEditorChange),
                ]}
                editorRef={richTextEditorRef}
                autoSetFocusOnMount
                onEditorKeyDown={{
                  keyCombo: KeyCombo.SHIFT_ENTER,
                  onComboFn: handleSubmitRequest,
                }}
                styleOverrides={{
                  minHeight: '50px',
                  maxHeight: '300px',
                  border: 'none',
                }}
                hideBottomSpace
                testId="respond-email-rich-text-editor"
              />
            ) : (
              <TextArea
                placeholder={i18n.t('threads-SubmitMessageInput-placeholder')}
                ref={messageInputRef}
                value={!submittingMessage ? messageVal : ''}
                onChange={(e) => setMessageVal(e.target.value)}
                onPaste={handleOnPaste}
                onKeyPress={handleEnterSubmit}
                name="messageInput"
                hideBottomSpace
                customTextAreaStyle={textareaCustomStyle}
                rows={1}
                data-testid="text-area-editor"
              />
            )}

            {attachments.length && !submittingMessage ? (
              <Carousel>
                {attachments.map((attachment, i) => (
                  <AttachmentTile
                    key={i}
                    attachment={attachment}
                    onDelete={() => dispatch(deleteAttachment(attachment.id))}
                    allowAnnotation
                    onAnnotate={() => {
                      dispatch(setAnnotationAttachmentIndex(i));
                      handleSetAnnotationModalOpen(true);
                    }}
                  />
                ))}
              </Carousel>
            ) : null}
            <LowerRow>
              <IconSection>
                <IconLabel
                  noOutline
                  data-for="no-name-contact"
                  data-tip
                  disabled={checkTemplateDisabled}
                  contrast="highColor"
                  onClick={() =>
                    !checkTemplateDisabled
                      ? setTemplateModalOpen(!templateModalOpen)
                      : ''
                  }
                >
                  <i className="ri-file-list-3-line" />
                </IconLabel>
                <AttachmentInput
                  type="file"
                  id="attachmentInput"
                  multiple={currentThreadIsAerialink ? null : 'multiple'}
                  data-testid="smi-file-input"
                  name="attachmentInput"
                  onChange={(e) => {
                    const files = Array.from(e.target.files).map(
                      (file) => file
                    );

                    if (
                      calcCombinedAttachmentSize(files, attachments) >
                      maxCombinedFileSizeLimit
                    ) {
                      dispatch(
                        openSnackbar(
                          i18n.t('slideouts-CreateInternal-30mb', {
                            fileSizeLimit: maxCombinedFileSizeLimit / 1000000,
                          }),
                          'error'
                        )
                      );
                      return;
                    }

                    if (files.length + attachments.length > 10) {
                      dispatch(
                        openSnackbar(
                          i18n.t('slideouts-CreateInternal-limit10'),
                          'error'
                        )
                      );
                      return;
                    }

                    if (currentThreadIsAerialink) {
                      validateAttachment(files[0]);
                    } else {
                      files.forEach((file) => validateAttachment(file));
                    }

                    e.target.value = null;
                  }}
                />
                <div>
                  <IconLabel
                    contrast="highColor"
                    title={i18n.t(
                      'threads-SubmitMessageInput-attachmentInputTitle'
                    )}
                    htmlFor="attachmentInput"
                  >
                    <i className="ri-attachment-2" />
                  </IconLabel>
                </div>
                <EmojiMenu
                  handleEmojiSelection={handleEmojiSelection}
                  open={emojiMenuOpen}
                  onClickOutside={() => setEmojiMenuOpen(false)}
                >
                  {({ emojiTouchTap }) => (
                    <IconLabel
                      contrast="highColor"
                      onClick={(e) => {
                        setEmojiMenuOpen(true);
                        emojiTouchTap(e);
                      }}
                      title={i18n.t('threads-SubmitMessageInput-insertAnEmoji')}
                      htmlFor="emojiInput"
                      dataTestId="smi-emoji-button"
                    >
                      <i className="ri-emotion-line" />
                    </IconLabel>
                  )}
                </EmojiMenu>
                {channelType === 'EMAIL' ? (
                  <EmailSignature
                    handleAddSignature={handleAddSignature}
                    emailSigOn={emailSigOn}
                    messageRef={messageInputRef}
                    withVerticalSeparator
                    positionCursorOnSignatureAdd={!enableRichTextEditor}
                  />
                ) : null}
                {!checkTemplateDisabled && (
                  <TemplateModal
                    channelType={channelType}
                    senderGroupId={senderGroupId}
                    addAttachment={handlePushAttachemnt}
                    contactId={contactId}
                    selectTemplate={handleTemplateApplication}
                    isOpen={templateModalOpen}
                    onRequestClose={() => setTemplateModalOpen(false)}
                    bottom="50px"
                  />
                )}
              </IconSection>
              <Button
                customStyle={() => '@media print{display: none;}'}
                onClick={handleSubmitRequest}
                dataTestId="send-button"
                disabled={!messageVal.trim() && !attachments.length}
              >
                {i18n.t('threads-SubmitMessageInput-send')}
              </Button>
            </LowerRow>
          </ContainElements>
        </WidthWrap>
      </Wrapper>
    </Justified>
  );
};

SubmitMessageInput.propTypes = {
  hasUnreadMessage: PropTypes.bool.isRequired,
  contactId: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  channelType: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
};

SubmitMessageInput.defaultProps = {
  contactId: '',
};

export default SubmitMessageInput;
