import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatDataTestName } from 'client-lib/src/lib/utils/helpers';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/app';
import { Heading1, Heading2, Heading3 } from '../Text/Text';
import InlineTextButton from '../Button/InlineTextButton';
import { BREAKPOINT_LG } from '../../utils/helpers/constants';

const WIDGET_HEIGHT = theme('fontSize', {
  default: '325px',
  large: '352px',
});

const WidgetContainer = styled.article`
  min-width: 430px;
  min-height: ${WIDGET_HEIGHT};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 24px;
  border: 2px solid ${THEMES.BORDER_COLOR};
  box-shadow: ${THEMES.BOX_SHADOW};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  max-width: 430px;
  flex: 2;
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    max-width: ${(props) => props?.breakpointWidth ?? props?.breakpointWidth};
    flex-grow: 2;
  }
  ${(props) => props.customStyle}
`;

const LargeNumberContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_TERTIARY};
  color: ${THEMES.FOREGROUND_HIGH};
  height: 80px;
  width: 80px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  min-height: 80px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  max-height: 80px;
  overflow-y: visible;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChildrenContainer = styled.div`
  padding-bottom: 0;
`;

const TotalHeading = styled(Heading1)`
  font-size: ${(props) => (props.totalLength >= 5 ? '24px' : '33px')};
`;

const Widget = ({
  title,
  subheader,
  total,
  children,
  customStyle,
  breakpointWidth,
  btnText,
  btnOnClick,
  btnDataTestId,
}) => {
  /**
   * Decrease Total header to H2 size when at or above 5 digits.
   */
  const totalLength = total.toString().length;
  return (
    <WidgetContainer
      breakpointWidth={breakpointWidth}
      customStyle={customStyle}
    >
      <HeaderContainer>
        <LargeNumberContainer>
          <TotalHeading
            totalLength={totalLength}
            dataTestId={`widget-total-heading-${formatDataTestName(title)}`}
          >
            {total}
          </TotalHeading>
        </LargeNumberContainer>
        <TitleWrapper>
          <Heading2>{title}</Heading2>
          {typeof subheader === 'string' ? (
            <Heading3>{subheader}</Heading3>
          ) : (
            subheader
          )}
        </TitleWrapper>
      </HeaderContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
      {btnText && (
        <ButtonContainer>
          <InlineTextButton
            noOutline
            onClick={btnOnClick}
            dataTestId={btnDataTestId}
          >
            {btnText}
          </InlineTextButton>
        </ButtonContainer>
      )}
    </WidgetContainer>
  );
};

Widget.propTypes = {
  title: PropTypes.string.isRequired,
  subheader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  total: PropTypes.number.isRequired,
  customStyle: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  btnText: PropTypes.string,
  btnOnClick: PropTypes.func,
  btnDataTestId: PropTypes.string,
  breakpointWidth: PropTypes.string,
};

Widget.defaultProps = {
  customStyle: () => {},
  subheader: undefined,
  btnText: undefined,
  btnOnClick: undefined,
  btnDataTestId: undefined,
  breakpointWidth: undefined,
};

export default Widget;
